<template>
  <validation-observer
    ref='form'
    #default='{ invalid }'
    tag='form'
    class='repeater__form'
    style='overflow: visible'
  >
    <b-modal
      v-model='show'
      content-class='package-modal rounded-8'
      dialog-class='wameed-modal-md'
      no-fade
    >
      <template slot='modal-header-close'>
        <close-icon />
      </template>
      <template slot='modal-title'>
        <div class='d-block text-center'>
          <h3 class='text-reg-18'>
            {{ modalData != null ? $t('ads.update_btn') : $t('ads.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>

        <b-col md='12'>
          <wameed-dropdown
            :searchable='true'
            v-model='form.vendor'
            :label="$t('featured.form.vendor')"
            :placeholder="$t('form.text.placeholder')"
            variant='disable'
            :items='getVendors'
            title='name'
            class='wameed_dropdown'

            :no_options="$t('common.no_options')"

            @input='onVendorChange'
          />
        </b-col>


      </b-row>


      <b-row>
        <b-col md='12'>
          <hr class='divider' />
        </b-col>

        <b-col md='6'>
          <Wameed-date-picker
            v-model='form.start_at'
            placeholder='YYYY-MM-DD'
            :config='{"type":"string","mask":"YYYY-MM-DD"}'
            mode='date'
            :label="$t('common.start_at')"
            @input='clearExpireDate'
          />
        </b-col>
        <b-col md='6'>
          <Wameed-date-picker
            v-model='form.end_at'
            placeholder='YYYY-MM-DD'
            :config='{"type":"string","mask":"YYYY-MM-DD"}'
            mode='date'
            :label="$t('common.end_at')"
            :minDate='minEndDate'
          />
        </b-col>
      </b-row>
      <template slot='modal-footer'>
        <wameed-btn
          classes='  text-med-14 text-white mx-0 rounded-8'
          :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
          :disabled='invalid'
          type='submit'
          variant='main'
          @onClick='submitOrder()'
        />
        <wameed-btn
          classes='  text-med-14 text-font-secondary mx-0 rounded-8 '
          :title="$t('btn.cancel')"
          type='button'
          variant='gray'
          @onClick='closeModal()'
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WameedBtn } from 'wameed-ui/dist/wameed-ui.esm';
import { mapActions, mapGetters } from 'vuex';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    modalData: {
      type: Object,
      default: null
    }

  },

  data() {
    return {
      form: {
        service: '',
        service_id: '',
        vendor: '',
        vendor_id: '',
        start_at: '',
        end_at: ''
      },
      showSuccessModal: false
    };
  },
  computed: {
    ...mapGetters({
      getVendors: 'admin/featured/getVendors',
      getServices: 'admin/featured/getCategories'
    }),
    minEndDate() {
      let date = this.form.start_at;
      if (!date) {
        date = Date();
      }
      return new Date(date.toString()).toString();
    },


    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
        this.form.service = this.getServices.filter(type => type.id === this.form.service_id)[0];
        this.form.vendor = this.getVendors.filter(vendor => vendor.id === this.form.vendor_id)[0];
        if (this.showRedirectDropDown) {
          this.form.redirect_obj = this.getRedirects.filter(redirect => redirect.id === this.form.redirect)[0];
        }
      } else {
        this.form = {
          service: '',
          service_id: '',
          vendor: '',
          vendor_id: '',
          redirect: '',
          start_at: '',
          end_at: ''
        };
      }
    }
  },
  created() {
    if (this.modalData != null) {
      this.form = this.modalData;
    }
  },
  methods: {
    ...mapActions({
      create: 'admin/featured/create',
      update: 'admin/featured/update'
    }),

    clearExpireDate() {
      if (this.form.start_at > this.form.end_at) {
        this.form.end_at = null;
      }
    },
    uploadProfileImage(value) {
      this.form.image = value;
    },
    uploadWebImage(value) {
      this.form.image_web = value;
    },

    async onTypeChange(type) {
      this.form.service = type;
      this.form.service_id = type.id;

    },
    async onVendorChange(vendor) {
      this.form.vendor = vendor;
      this.form.vendor_id = vendor.id;

    },


    submitOrder() {

      if (this.modalData == null) {

        this.form.type = this.$route.meta.type;
        this.form.service_type = this.$route.meta.type;

        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      } else {
        this.update({
          ...this.form,
          id: this.modalData.id,
          service_type : this.$route.meta.type
        }).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      }
    },


    closeModal() {
      this.form = {

        service: '',
        vendor: '',
        vendor_id: '',
        redirect: '',
        start_at: '',
        end_at: ''
      };
      this.show = false;
    }
  }
};
</script>
